'use client';

import { useEffect, useRef, type FunctionComponent } from 'react';
import useStore from '@/store';
import { withErrorBoundary } from 'react-error-boundary';

import initGoogletag from '@/utils/ads/googlePublisherTag/initGoogletag';
import isBot from '@/utils/isBot';
import { NoUiErrorFallback } from '@/components/ErrorFallback';

import { type AdConfigSetupClientProps } from './AdConfigSetup.props';

const AdConfigSetupClient: FunctionComponent<AdConfigSetupClientProps> = ({
  adConfig,
  geolocation,
}) => {
  const { setAdConfig, setGeoLocationData } = useStore();
  const isBotTraffic = useRef<boolean>(isBot(geolocation.clientIp));

  // Sets client IP, country code and postal (zip) code in the store
  useEffect(() => {
    setGeoLocationData(geolocation);
  }, [geolocation, setGeoLocationData]);

  useEffect(() => {
    initGoogletag();

    googletag.cmd.push(() => {
      if (isBotTraffic.current) {
        // Set page level targeting params
        googletag.pubads().setTargeting('bot', 'true');
      }
    });
  }, [isBotTraffic]);

  // Sets ad config in the store
  useEffect(() => {
    setAdConfig({
      ...adConfig,
      adTargeting: {
        ...adConfig.adTargeting,
        // If we are setting `bot` at the page level, so we need this?
        ...(isBotTraffic.current && { bot: 'true' }),
      },
    });
  }, [adConfig, isBotTraffic, setAdConfig]);

  return null;
};

export default withErrorBoundary(AdConfigSetupClient, {
  FallbackComponent: NoUiErrorFallback,
});
